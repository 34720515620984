import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService, PredefinedUserZone } from '@saliente/common-auth';
import { DocumentFileModel, DocumentFileStatus, FinancialDocumentEntryType, PersonForAccountingModel } from '@saliente/common-documents';
import { $, coerceBooleanProperty, GalleryImage, MessageBoxButton, MessageBoxService } from '@saliente/library';
import { CLIENTS_ROUTES } from '../clients.routes.constants';
import { IClientDocumentsService } from './documents.service';
import { take } from 'rxjs/operators';
import { AccDocumentFileModel } from './documents.models';
import { IClientDocumentItemParent } from 'src/app/common/document-display/client-document-item-parent';
import { DocumentsNavigationService } from 'src/app/common/documents/documents.navigation';
import { NavigableDataSource } from 'src/app/common/general/navigable_datasource';
import { DocumentDisplayService } from 'src/app/common/document-display';
import { DocumentIndentificationModel } from 'src/app/common/document-display/document-display.models';


const linkify = require('linkify-it')();
const emptyArray: any[] = [];
@Component({
	selector: 'client-document-item',
	templateUrl: 'document-item.component.html',
	styleUrls: ['document-item.component.scss']
})
export class ClientDocumentItemComponent implements OnInit, OnDestroy {
	private currentPageIndex: number = 0;

	public showInfoDialogRequested: boolean = false;
	public showInfoDialog: boolean = false;
	public showChangeEmployeeDialog: boolean = false;
	public infoDialogModel: DocumentFileModel;
	public comments: any[] = [];
	public commentsToDisplay: any[] = [];
	public isZeus: boolean;

	@Output() public deleted: EventEmitter<any> = new EventEmitter<any>()

	@ViewChild('replaceDocumentFile') private replaceDocumentFile: ElementRef;
	public DocumentFileStatus = DocumentFileStatus;

	private documentsService: IClientDocumentsService;

	private _model: DocumentFileModel;
	@Input() get model(): DocumentFileModel {
		return this._model;
	}
	set model(value) {
		this._model = value;

		this.comments = [];
		if (this._model) {
			if (this._model.comments) {
				this._model.comments.sort((a, b) => {
					return b.dateTime.getTime() - a.dateTime.getTime();
				});
				this._model.comments.forEach((comment) => {
					if (comment.comment.includes('EFacturaModule')) {
						comment.comment = comment.comment.replace('EFacturaModule', 'modulul e-Factura');
					}

					//AM. 10.05.2024. task 7992, comment #17
					//if (this.comments.indexOf(comment.comment) === -1) {
						this.comments.push(comment.comment);
					//}
				});

				this.commentsToDisplay = this.comments.slice(0, 5); // first 5 comments
			}
			const isOwnDocument = this._model.created && this._model.created.userEid == this.authService.user.externalId;
			this.hasDeleteRight = this.parentView.hasDeleteRight || (isOwnDocument && this.parentView.hasDeleteOwnRight);
			this.hasAdnotateRight = this.parentView.hasAdnotateRight || (isOwnDocument && this.parentView.hasAdnotateOwnRight);
			this.hasEFacturaDeleteRight = this.parentView.hasEFacturaDeleteRight;
		} else {
			this.hasDeleteRight = false;
			this.hasAdnotateRight = false;
			this.hasEFacturaDeleteRight = false;
		}
	}

	private __extended__: boolean = true;
	@Input() get extended(): boolean {
		return this.__extended__;
	}
	set extended(value) {
		this.__extended__ = coerceBooleanProperty(value);
	}

	private _isDocumentDisplay: boolean = false;
	@Input() get isDocumentDisplay(): boolean {
		return this._isDocumentDisplay;
	}
	set isDocumentDisplay(value) {
		this._isDocumentDisplay = coerceBooleanProperty(value);
	
	}

	@Input() public documentType: string;
	@Input() public documentsNavigator: NavigableDataSource<string, DocumentFileModel>;

	@Output() public close = new EventEmitter();

	private _showDocument = false;
	get showDocument(): boolean {
		return this._showDocument;
	}
	set showDocument(value: boolean) {
		this._showDocument = value;
		if (this._showDocument) {
			this.checkAccMetadata();
		}
	}

	public displayAccMetadata: any = null;
	public hasDetailedAccMetadata: boolean = false;

	galleryOptions: any = {};
	galleryImages: GalleryImage[];


	public get documentStatusName() {
		return this.documentsService.documentStatusName(this.model.status.statusCode, false);
	}

	private hasDeleteRight: boolean = false;
	public get allowDeleteDocument() {
		return this.hasDeleteRight;
	}

	private hasEFacturaDeleteRight: boolean = false;
	public get allowDeleteEFacturaDocument() {
		return this.hasEFacturaDeleteRight;
	}

	public get isKeezCompany(): boolean {
		return this.authService.user.selectedCompany.fiscalNumber == "37888790"
	}

	public get isDeleteVisible(): boolean {
		if (this.model.documentIsProtected && !this.isKeezCompany && !this.isZeus) {
			return false;
		}
		else {
			return ((!this.model.isEFacturaModule && this.allowDeleteDocument) || (this.model.isEFacturaModule && this.allowDeleteEFacturaDocument)) && !this.model.isInvoice; 
		}
	}

	protected hasMarkUsableRight = false;
	public get allowMarkUsabe() {
		return this.hasMarkUsableRight;
	}

	private hasAdnotateRight: boolean = false;
	public get allowAdnotate() {
		return this.hasAdnotateRight;
	}

	private hasCostCenterViewRight: boolean = false;
	public get allowViewCostCenter() {
		return this.hasCostCenterViewRight;
	}

	private hasCostCenterEditRight: boolean = false;
	public get allowEditCostCenter() {
		return this.hasCostCenterEditRight && this.model.status.statusCode === DocumentFileStatus.Booked;
	}

	public sendCommentInConnectRight: boolean = false;

	public get allowChangeEmployee() {
		let hasEmployeeEid = -1;
		let isStatementOfAccount = true;

		if (this.displayAccMetadata != null) {
			isStatementOfAccount = this.displayAccMetadata.entryType == FinancialDocumentEntryType.StatementOfAccount;

			if (this.displayAccMetadata.postings != undefined) {
				hasEmployeeEid = this.displayAccMetadata.postings.findIndex((p: any) => p.employeePIN != null);
			}
		}
		
		return this.model.status.statusCode === DocumentFileStatus.Booked && !isStatementOfAccount && hasEmployeeEid != -1;
	}

	public showCommentsDialog: boolean = false;

	constructor(private authService: AuthService, private router: Router, private renderer: Renderer2, private toastr: ToastrService,
		//@Inject(forwardRef(() => ClientDocumentsComponent)) private parentView: ClientDocumentsComponent,
		private parentView: IClientDocumentItemParent, private messageBoxService: MessageBoxService, private el: ElementRef, private cdr: ChangeDetectorRef,
		private documentDisplayService: DocumentDisplayService) {
			this.documentsService = this.parentView.documentsService;
			this.hasMarkUsableRight = this.parentView.hasMarkUsableRight;

			this.hasCostCenterViewRight = this.authService.user.isInRole('COST_CENTER_VIEW');
			this.hasCostCenterEditRight = this.authService.user.isInRole('COST_CENTER_EDIT');
			this.sendCommentInConnectRight = this.authService.user.isInRole('EXPERT') && this.authService.selectedZoneCode === PredefinedUserZone.Expert;
			this.isZeus = this.authService.isInRole('ZEUS');
	}

	public ngOnInit() {
		var self = this;
		if (self.extended) {
			this.initGallery();
		}
	}

	public initGallery() {
		var self = this;
		let thumbnailExtSrc: any;

		if (this.model.metadataEx.fileName) {
			const ext = this.model.metadataEx.fileName.split('.').pop().toLowerCase();
			thumbnailExtSrc = self.documentsService.getDocumentThumbnailExtSrc(ext);
		}

		this.galleryOptions = {
			inline: true, navbar: false, loop: true,
			view: function (e: any) {
				self.setCurrentPageIndex(e.detail.index)
			},
			thumbnailExtSrc: thumbnailExtSrc, //AM. 23.11.2022. task 5107
			thumbnailExtDefaultRatio: 0.6
		};
		
		this.galleryImages = [];

		this.model.pages.forEach((page) => {
			this.galleryImages.push({
				dataSrc: this.documentsService.getDocumentSrc(this.model, page),
				class: "lazy"
			});
		});
	}

	public ngOnDestroy() {}

	private _positionTop: number;
	public positionTop() {
		if (!this._positionTop) {
			this._positionTop = $(this.el.nativeElement).offset().top - $('.cd-bar').position().top - $('.cd-bar').height();
		}
		return this._positionTop;
	}

	private _positionBottom: number;
	public positionBottom() {
		if (!this._positionBottom) {
			const positionTop = this.positionTop();
			this._positionBottom = positionTop + $(this.el.nativeElement).parent().height();
		}
		return this._positionBottom;
	}

	public resetPosition() {
		this._positionTop = null;
		this._positionBottom = null;
	}

	public isInView() {
		const offset = 500;
		const $window = $(window);
		const windowHeight = $(window).height();
		const scrollTop = $window.scrollTop();
		const positionTop = this.positionTop();
		const positionBottom = this.positionBottom();
		return (positionTop + offset >= scrollTop && positionTop - offset <= scrollTop + windowHeight) || (positionBottom + offset >= scrollTop && positionBottom - offset <= scrollTop + windowHeight);
	}

	public resetIsInView() {
		this._positionTop = null;
		this._positionBottom = null;
	}

	private setCurrentPageIndex(index: number) {
		if (index < 0) {
			index = 0;
		}
		if (index >= this.model.pages.length) {
			index = this.model.pages.length - 1;
		}
		this.currentPageIndex = index;
	}

	public extendedOverlayClass(): string {
		return this.documentsService.extendedOverlayClass(this.model.status.statusCode);
	}

	public getOriginalDocumentSrc() {
		return this.documentsService.getOriginalDocumentSrc(this.model, this.getCurrentPage());
	}

	public getCurrentPage() {
		try {
			return this.model.pages[this.currentPageIndex];
		} catch {
			return null;
		}
	}

	public download(file: DocumentFileModel) {
		if (this.extended) {
			try {
				this.documentsService.downloadDocument(file, this.getCurrentPage()).subscribe();
			} catch { }
		} else {
			this.documentsService.downloadDocument(file).subscribe();
		}
	}

	public downloadFullDocument(file: DocumentFileModel) {
		const subscription = this.documentsService.downloadFullDocument(file).subscribe(() => {
			subscription.unsubscribe();
		});
	}

	public downloadOriginal(file: DocumentFileModel) {
		if (this.extended) {
			try {
				const subscription = this.documentsService.downloadOriginalDocument(file, this.getCurrentPage()).subscribe(() => {
					subscription.unsubscribe();
				});
			} catch { }
		} else {
			const subscription = this.documentsService.downloadOriginalDocument(file).subscribe(() => {
				subscription.unsubscribe();
			});
		}
	}

	public parseComment(comment: string) {
		const matches = linkify.match(comment);
		if (matches && matches.length) {
			let commentHtml = comment;
			matches.reverse().forEach((match: any) => {
				commentHtml = commentHtml.substring(0, match.index) +
					`<a href="${match.url}" target="_blank">${match.text}</a>` +
					commentHtml.substring(match.lastIndex);
			});
			return commentHtml;
		}
		return comment;
	}

	public deleteDocumentFileModel: any = null;
	public showDeleteDocumentFileForm: boolean = false;
	public delete(file: DocumentFileModel) {
		const self = this;
		// if e-Factura
		if (self.model.isEFacturaModule) {
			if (self.allowDeleteEFacturaDocument) {
				this.deleteEFactura(file);
			}
		}
		else {
			if (self.allowDeleteDocument) {
				self.deleteDocumentFileModel = { file: file, comment: '' };

				// AM, 01.11.2021, task 5356: only Booked documents should not be delete in a closed month
				// CM 20220103 task 5601
				if (self.model.status.statusCode === DocumentFileStatus.Booked
					&& file.documentDateEx.getTime() < self.authService.user.selectedCompany.firstDayAfterLastClosedAccountingPeriod.getTime()) {
					self.messageBoxService.show({
						title: 'Ștergere document',
						message: "Lună închisă - nu puteți șterge documentele contate în luni închise.",//"Documentul nu poate fi șters pentru că luna contabilă a fost închisă",
						buttons: [MessageBoxButton.ok()]
					});
				} else {
					if (self.model.status.statusCode === DocumentFileStatus.Booked) {
						self.deleteDocumentFileModel.warning = "Atenție, veți șterge documentul dar și înregistrările atașate.";
						//'Acest document este deja procesat, este posibil să nu poată fi șters. Dacă apar probleme vei fi notificat.'
					}
					self.showDeleteDocumentFileForm = true;
					setTimeout(() => {
						$('#txtComment textarea').focus();
					}, 100);
				}
			}
		}
	}

	public deleteEFactura(file: DocumentFileModel) {
		const self = this;
		self.messageBoxService.show({
			title: 'Ștergere document',
			message: 'Doriți să ștergeți acest document?',
			defaultButton: 'no',
			buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
			callback: function (result: string) {
				if (result === 'yes') {
					const subscription = self.documentsService.ignoreEFacturaByDocumentEid(self.model).subscribe((res) => {
						subscription.unsubscribe();
						if (res && self.parentView) {
							self.parentView.initLazy();
							self.deleted.emit(self.model);
						}
					});
				}
			},
		});
	}

	public closeDeleteDocumentFile(save: boolean, deleteDocumentFileForm: NgForm = null) {
		const self = this;
		if (save && deleteDocumentFileForm != null) {
			if (deleteDocumentFileForm.form != null && deleteDocumentFileForm.form.valid) {
				const subscription = self.documentsService.deleteDocument(this.deleteDocumentFileModel.file, this.deleteDocumentFileModel.comment).subscribe((res) => {
					subscription.unsubscribe();
					if (res && self.parentView) {
						self.parentView.initLazy();
					}
					this.showDeleteDocumentFileForm = false;
					this.deleteDocumentFileModel = null;
					self.deleted.emit(self.model);
				});
			}
		} else {
			this.showDeleteDocumentFileForm = false;
			this.deleteDocumentFileModel = null;
		}
	}

	public replace(file: DocumentFileModel) {
		const self = this;
		self.messageBoxService.show({
			title: 'Inlocuire document',
			message: "Dorești să inlocuiești acest document?",
			defaultButton: 'no',
			buttons: [MessageBoxButton.yes(), MessageBoxButton.no()],
			callback: function (result: string) {
				if (result === 'yes') {
					self.renderer.setAttribute(self.replaceDocumentFile.nativeElement, 'data-document', file.id);
					self.replaceDocumentFile.nativeElement.click();
				}
			}
		});
	}


	public onReplaceDocumentFileChange(event: any) {
		const documentEid = this.replaceDocumentFile.nativeElement.getAttribute('data-document');
		this.documentsService.uploadDocument(this.replaceDocumentFile.nativeElement.files[0], documentEid)
			.subscribe((data) => {
				if (data.done) {
				}
				this.replaceDocumentFile.nativeElement.value = null;
			}, (err) => {
				this.replaceDocumentFile.nativeElement.value = null;
				if (err) {
					this.toastr.error('Eroare la încărcarea documentului! Te rog să încerci din nou.');
				}
			});
	}

	public setCostCenter() {
		const documentsService: any = this.documentsService,
			model: any = this.model;
		documentsService.setCostCenter(model).subscribe();
	}

	public openChangeEmployeeDialog() {
		this.showChangeEmployeeDialog = true;
	}

	public closeChangeEmploeeDialog(save: boolean, formData: any = null) {
		if (save && formData != null) {
			if (formData.changeEmployeeForm.form.valid && formData.employee != undefined) {
				this.changeEmployee(formData.employee);
			}
		}

		this.showChangeEmployeeDialog = false;
	}

	public changeEmployee(employee: PersonForAccountingModel) {
		const documentsService: any = this.documentsService,
			model: any = this.model;
		documentsService.setEmployee(model, employee).subscribe((result: boolean) => {
			if (result) {
				this.checkAccMetadata(true);
				this.toastr.success("Angajatul a fost schimbat cu succes!");
			}
		});
	}

	public updateDueDate() {
		const documentsService: any = this.documentsService,
			model: any = this.model;
		documentsService.updateDueDate(model, this.displayAccMetadata.dueDate).subscribe();
	}

	public showInfo(e: any, file: DocumentFileModel) {
		if (e.detail > 3) {
			this.infoDialogModel = file;
			this.showInfoDialog = true;
			this.showInfoDialogRequested = false;
		}
	}

	public closeInfoDialog() {
		this.showInfoDialog = false;
	}

	private setDisplayAccMetadata() {
		const model: any = this.model;
		let accMetadata = model.accMetadata === emptyArray ? null : model.accMetadata;
		this.hasDetailedAccMetadata = false;
		if (accMetadata && accMetadata.length) {
			this.displayAccMetadata = accMetadata[0];
			this.hasDetailedAccMetadata = !!accMetadata.find((finData: any) => finData.postings && finData.postings.length > 0);
			if (this.displayAccMetadata.entryType == FinancialDocumentEntryType.StatementOfAccount) {
				this.displayAccMetadata = accMetadata[accMetadata.length - 1];
				this.displayAccMetadata.currencyAmount = null;
			}
		} else {
			this.displayAccMetadata = null;
		}

		if (this.displayAccMetadata) {
			this.displayAccMetadata.dueDateVisible = this.displayAccMetadata.postings
				&& (this.displayAccMetadata.entryType == FinancialDocumentEntryType.SaleInvoice 
					|| this.displayAccMetadata.entryType == FinancialDocumentEntryType.PurchaseInvoice
					|| this.displayAccMetadata.entryType == FinancialDocumentEntryType.AutoInvoice
					)
		}
	}

	private accMetadataSubscription: any = null;
	public checkAccMetadata(force: boolean = false) {
		const documentsService: any = this.documentsService,
			model: any = this.model;
		if ((!model.accMetadata && documentsService.accMetadata) || force) {
			this.accMetadataSubscription = documentsService
				.accMetadata(this.model)
				.subscribe((accMetadata: any) => {
					this.accMetadataSubscription && this.accMetadataSubscription.unsubscribe();
					model.accMetadata = accMetadata || emptyArray;
					this.setDisplayAccMetadata();
				}, () => {
					this.accMetadataSubscription && this.accMetadataSubscription.unsubscribe();
					model.accMetadata = emptyArray;
					this.setDisplayAccMetadata();
				});
		} else {
			this.setDisplayAccMetadata();
		}
	}

	public documentFileCommentModel: any = null;
	public showFileCommentForm: boolean = false;
	public addComment(file: DocumentFileModel) {
		if (this.allowAdnotate) {
			this.documentFileCommentModel = { documentEid: file.id, comment: ''};

			// AM. 27.03.2023. task 7327: only for EXPERT
			if (this.sendCommentInConnectRight) {
				this.documentFileCommentModel.alsoSendInConnect = false;
			}

			this.showFileCommentForm = true;
			setTimeout(() => {
				$('#txtComment textarea').focus();
			}, 100);
		}
	}

	public get accMetadata() : any {
		if (this.model instanceof AccDocumentFileModel)
			return this.model.accMetadata;
		return null;
	}

	public get costCenter() : string {
		if (this.model instanceof AccDocumentFileModel)
			return this.model.costCenter;
		return null;
	}

	public set costCenter(val: string) {
		if (this.model instanceof AccDocumentFileModel)
			this.model.costCenter = val;
	}

	public closeDocumentFileComment(save: boolean, documentFileCommentForm: NgForm = null) {
		if (save && documentFileCommentForm != null) {
			if (documentFileCommentForm.form != null && documentFileCommentForm.form.valid) {
				this.documentsService
					.addDocumentCommentStream(this.model, this.documentFileCommentModel.comment)
					.subscribe((saved) => {
						if (saved) {
							this.comments.splice(0, 0, this.documentFileCommentModel.comment);
							this.commentsToDisplay = this.comments.slice(0, 5); // first 5 comments

							if (this.sendCommentInConnectRight && this.documentFileCommentModel.alsoSendInConnect) {
								let messageContent = this.documentFileCommentModel.comment + '\n\n' + this.getDocumentUrl();
								this.addMessageInConnect(this.model, messageContent);
							}

							this.showFileCommentForm = false;
							this.documentFileCommentModel = null;
						}
					});
			}
		} else {
			this.showFileCommentForm = false;
			this.documentFileCommentModel = null;
		}
	}

	public addMessageInConnect(model: DocumentFileModel, messageContent: string) {
		this.documentsService.addChatMessage(model, this.documentType == "accounting" ? 'FIN' : this.documentType == "payroll" ? 'PAY' : 'TECH', messageContent)
			.pipe(take(1)).subscribe((result) => {
				if (result) {
					this.toastr.success('Mesajul a fost trimis în Connect.');
				}
				else {
					this.toastr.error('Mesajul nu a putut fi trimis în Connect.');
				}
		});
	}

	public unblurDocumentFileModel: any = null;
	public showUnblurDocumentFileForm: boolean = false;
	public unblur(file: DocumentFileModel) {
		const self = this;
		self.unblurDocumentFileModel = { file: file, comment: '' };
		self.showUnblurDocumentFileForm = true;
		setTimeout(() => {
			$('#txtComment textarea').focus();
		}, 100);
	}

	public closeUnblurDocumentFile(save: boolean, unblurDocumentFileForm: NgForm = null) {
		const self = this;
		if (save && unblurDocumentFileForm != null) {
			if (unblurDocumentFileForm.form != null && unblurDocumentFileForm.form.valid) {
				self.documentsService.unblurDocument(this.unblurDocumentFileModel.file, this.unblurDocumentFileModel.comment).subscribe((res) => {
					if (res) {
						this.model.status.statusCode = DocumentFileStatus.Received;
						
						if (self.parentView) {
							self.parentView.initLazy();
						}
					}
					this.comments.unshift(this.unblurDocumentFileModel.comment);
					this.showUnblurDocumentFileForm = false;
					this.unblurDocumentFileModel = null;
				});
			}
		} else {
			this.showUnblurDocumentFileForm = false;
			this.unblurDocumentFileModel = null;
		}
	}

	public goToInvoice(file: DocumentFileModel) {
		this.router.navigate([CLIENTS_ROUTES.ROOT, CLIENTS_ROUTES.INVOICES, 'edit', file.id]);
	}

	public downloadAccMetadata() {
		const documentsService: any = this.documentsService,
			model: any = this.model;
		documentsService.downloadAccMetadata(model).pipe(take(1)).subscribe();
	}

	public getDocumentUrl(): string {
		let routeComponent = this.documentType == "accounting" ? CLIENTS_ROUTES.ACC_DOCUMENTS : this.documentType == "payroll" ? CLIENTS_ROUTES.PAYROLL_DOCUMENTS : CLIENTS_ROUTES.ACC_DOCUMENTS;
		
		let documentUrl = `${window.location.origin}${CLIENTS_ROUTES.ROOT}/${routeComponent}/${this.model.id}`;//this.router.url;
		let documentFileName = (this.model.metadataEx != undefined && this.model.metadataEx.fileName != undefined)
			? `${this.model.metadataEx.fileName}` : "Document";
		// let documentFileName = (this.model != undefined && this.model.metadataEx != undefined && this.model.metadataEx.fileName != undefined)
		// 	? `${this.model.metadataEx.fileName}` : "Document";

		let completeText = `[${documentFileName}](${documentUrl})`;

		return completeText;
	}

	public copyDocumentUrl() {
		let completeText = this.getDocumentUrl();

		let textToCopy = (e : ClipboardEvent) => {
			e.clipboardData.setData('text/plain', completeText);
			e.preventDefault();
		};

		document.addEventListener('copy', textToCopy);
		document.execCommand('copy');
		document.removeEventListener('copy', textToCopy);

		this.toastr.success('Linkul documentului pentru Connect a fost copiat în clipboard.', "", { timeOut: 2000 });
	}

	public hasNextRecord() : boolean {
		if (this.documentsNavigator == null)
			return false;
		else {
			return this.documentsNavigator.hasNext(this.model.id);
		}
	}

	public hasNavigation(): boolean {
		return this.documentsNavigator != null;
	}

	public hasPreviousRecord() : boolean {
		if (this.documentsNavigator == null)
			return false;
		else {
			return this.documentsNavigator.hasPrevious(this.model.id);
		}
	}

	public async getPreviousDocument() {
		if (this.documentsNavigator.hasPrevious(this.model.id) == null) 
			return;

		let prevDocumentId = await this.documentsNavigator.getPreviousKey(this.model.id);
		if (prevDocumentId != undefined) {
			this.loadDocumentDetails(prevDocumentId);
		}
	}

	public async getNextDocument() {
		if (this.documentsNavigator.hasNext(this.model.id) == null) 
			return;

		let nextDocumentId = await this.documentsNavigator.getNextKey(this.model.id);
		if (nextDocumentId != undefined) {
			this.loadDocumentDetails(nextDocumentId);
		}
	}

	public async loadDocumentDetails(documentId: string) {
		let documentIndentification = new DocumentIndentificationModel();
		documentIndentification.externalId = documentId;
		documentIndentification.documentType = this.documentType;

		let documentDetails = await this.documentDisplayService.getDocument(documentIndentification);
		if (documentDetails) {
			this.model = documentDetails;
			this.initGallery();
			this.checkAccMetadata();
		}
	}

	public openCommentsDialog() {
		this.showCommentsDialog = true;
	}

	public closeCommentsDialog() {
		this.showCommentsDialog = false;
	}
}
